
.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100vh;
  flex-direction: row;
}

.navbar-custom {
  font-size:large;
  background-color: transparent;
  
}

.navbar__link {
  margin-left: 0.75em;
  margin-right: 0.75em;
  font-size: 1em;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: .1em;
  text-indent: .3em;
  border-bottom: 3px solid transparent;
}

.navbar__link::after {
  transition: all ease-in-out .2s;
  background: none repeat scroll 0 0;
  content: "";
  display: block;
  margin-top: 2px;
  height: 3px;
  width: 0;
}

 .navbar__link:hover::after {
  visibility: visible;
  width: 40%;
}

.navbar__link--active::after {
  transition: all ease-in-out .2s;
  width: 100%;
}

.navbar__link--active:hover::after {
  width: 100%;
}

.MainApp {
  margin-top: 8vh;
  height: 90vh;
}

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.section-content-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.Typewriter {
  display: inline-block;
}

.Typewriter__wrapper {
  font-size: xx-large;
  font-weight: bold;
}

.Typewriter__cursor {
  font-size: xx-large;
  font-weight: bold;
}

section.timeline-card-content {
  justify-content: center;
}

section.timeline-card-content p.card-title {
  font-size: 1.5em;
  margin-top: 25px;
}

section.timeline-card-content p.card-sub-title {
  font-size: 1.25em;
  margin-top: 10px;
}

section.timeline-card-content div.card-description > p {
  font-size: 1.1em;
  margin-top: 10px;
  font-weight: 500;
}

.social {
  position: relative;
  margin-top: 60px;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

@media only screen and (min-width: 768px) and (max-width: 992px) {

  .navbar__link {
    margin-left: 0.4em;
    margin-right: 0.4em;
    font-size: 0.9em;
    cursor: pointer;
    letter-spacing: .1em;
    text-indent: .2em;
  }
}

@media (max-width: 768px) {

  .navbar__link::after {
    background: none;
    content: "";
    display: none;
    margin-top: 0;
    height: 0;
    width: 0;
  }

  .navbar__link--active::after {
    width: 0;
  }
  
  .navbar__link--active:hover::after {
    width: 0;
  }

  .navbar__link:hover::after {
    width: 0;
  }

  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
}

.header {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 25px;
  margin-top: 25px;
}

#background-video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the video is behind other content */
  overflow: hidden; /* Hide any overflow content */
}

/* CSS styles for the video */
#background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the video covers the entire container */
}

#google_translate_element {
  position: fixed; /* Ensure translation button remains visible */
      top: 80px; /* Position at the top of the viewport */
      right: 30px; /* Position slightly to the right */
      width: 200px; /* Set a fixed width */
      z-index: 9999; /* Ensure it's above other content */
      background-color: transparent; /* Transparent background color */
      padding: 10px 0; /* Add padding for better appearance */
      text-align: center;
}

.rounded-image {
  border-radius: 20%;
  border: 5px solid #000;
  width: '100%'; 
  height: 'auto';
}

.black-container {
  background-color: #000; 
  padding: 50px;
  border-radius: 5%;

}
